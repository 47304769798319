<template lang="pug">
div
	StoryblokComponent(v-if="story && story.content" :blok="story.content")
</template>

<script setup lang="ts">
definePageMeta({
	layout: 'lander-layout',
	pageTransition: {
		name: 'fade',
		mode: 'out-in'
	},
	middleware: ['dynamic-transitions', 'authed-root-guard']
})

const { locale, t } = useI18n()

const { $preview } = useNuxtApp()
const errorMessage = ref()
const errorOccured = ref(false)

const fixSlug = (slug: string) => {
	let newSlug = slug
	if (!newSlug.endsWith('/')) {
		newSlug = newSlug + '/'
	}
	if (newSlug.startsWith('/')) {
		newSlug = newSlug.substring(1)
	}
	return newSlug.toLowerCase()
}

const version = $preview ? 'draft' : 'published'
const story = await useAsyncStoryblok(
	fixSlug('consumer'),
	{ version, language: locale.value, resolve_links: 'url' } // API Options
)
if (story.value?.status) {
	errorOccured.value = true
	errorMessage.value = story.value.response
}
// Meta
const { setMetaTitle, setMetaDescription } = useMetaTags()

const pageTitle = t('meta.index.title')
const metaTitle = setMetaTitle(pageTitle)
const metaDesc = setMetaDescription(t('meta.index.description'))

useHead({
	title: pageTitle,
	meta: [
		...metaTitle,
		...metaDesc
	]
})
</script>

<style lang="sass" scoped>
.container
	padding-bottom: $spacing-80
	&.section
		padding-top: $spacing-24
	& > *:not(.loading):not(:first-child)
		margin-top: $spacing-24
</style>

<style lang="sass">
.shepherd-element
	background: $color-purple-60
	max-width: 768px
	padding: $spacing-16 $spacing-16 $spacing-48 $spacing-48

	.shepherd-text
		color: $color-grey-30
		@include fontSize(m)
		padding: 0 !important
		margin-right: $spacing-32

.shepherd-cancel-icon
	background-image: url("~/assets/images/close-circle.svg")
	width: 40px
	span
		visibility: hidden

.shepherd-header
	background: $color-purple-60 !important
	overflow-wrap: break-word
	color: #fff
	@include font(basier, bold)
	padding: 0 !important
	display: flex
	justify-content: space-between
	align-items: flex-start

.shepherd-title
	@include font(basier, bold)
	@include fontSize(3xl)
	max-width: 600px
	margin: $spacing-24 0 $spacing-16 0
	color: $color-grey-10

.shepherd-arrow:before
	background: $color-purple-60 !important

.shepherd-button
	width: 100%
	height: 48px
	background-color: white
	color: black
	border-radius: $radius-default
	@include font(basier, medium)
	@include fontSize(s)
	margin: $spacing-16 $spacing-16 0 0
	&:hover
		background: $color-grey-10 !important
		color: black !important

.shepherd-button-secondary
	background: $transparent-white-16 !important
	color: $color-grey-30 !important
	&:hover
		background: $transparent-white-12 !important
		color: $color-grey-30 !important

.shepherd-footer
	padding: 0 !important
	margin: $spacing-16 $spacing-32 0 0
	flex-direction: column-reverse
</style>
